/* eslint-disable max-lines */
/*
  These are only used for translation purposes. Subjects are defined at
  ../definitions/subjects.js.
*/
const SUBJECTS = [
  {
    id: 'äidinkieli',
    'fi-FI': 'Äidinkieli',
    'sv-FI': 'Modersmål',
  },
  {
    id: 'äidinkieli-suomi-lukutaidon-koe',
    'fi-FI': 'Äidinkieli, suomi, lukutaidon koe',
    'sv-FI': 'Modersmål, finska, läskompetensprovet',
  },
  {
    id: 'äidinkieli-ruotsi-essee',
    'fi-FI': 'Äidinkieli, ruotsi, esseekoe',
    'sv-FI': 'Modersmål, svenska, essäprov',
  },
  {
    id: 'äidinkieli-ruotsi-tekstitaito',
    'fi-FI': 'Äidinkieli, ruotsi, tekstitaidon koe',
    'sv-FI': 'Modersmål, svenska, textkompetens',
  },
  {
    id: 'äidinkieli-ruotsi-lukutaidon-koe',
    'fi-FI': 'Äidinkieli, ruotsi, lukutaidon koe',
    'sv-FI': 'Modersmål, svenska, läskompetensprovet',
  },
  {
    id: 'äidinkieli-suomi-essee',
    'fi-FI': 'Äidinkieli, suomi, kirjoitustaito',
    'sv-FI': 'Modersmål, finska, essäprov',
  },
  {
    id: 'äidinkieli-suomi-tekstitaito',
    'fi-FI': 'Äidinkieli, suomi, tekstitaidon koe',
    'sv-FI': 'Modersmål, finska, textkompetens',
  },
  {
    id: 'biologia',
    'fi-FI': 'Biologia',
    'sv-FI': 'Biologi',
  },
  {
    id: 'elämänkatsomustieto',
    'fi-FI': 'Elämänkatsomustieto',
    'sv-FI': 'Livsåskådningskunskap',
  },
  {
    id: 'englanti',
    'fi-FI': 'Englanti',
    'sv-FI': 'Engelska',
  },
  {
    id: 'englanti-lyhyt',
    'fi-FI': 'Englanti, lyhyt oppimäärä',
    'sv-FI': 'Engelska, kort lärokurs',
  },
  {
    id: 'englanti-pitkä',
    'fi-FI': 'Englanti, pitkä oppimäärä',
    'sv-FI': 'Engelska, lång lärokurs',
  },
  {
    id: 'espanja',
    'fi-FI': 'Espanja',
    'sv-FI': 'Spanska',
  },
  {
    id: 'espanja-lyhyt',
    'fi-FI': 'Espanja, lyhyt oppimäärä',
    'sv-FI': 'Spanska, kort lärokurs',
  },
  {
    id: 'espanja-pitkä',
    'fi-FI': 'Espanja, pitkä oppimäärä',
    'sv-FI': 'Spanska, lång lärokurs',
  },
  {
    id: 'uskonto-evlut',
    'fi-FI': 'Evankelisluterilainen uskonto',
    'sv-FI': 'Evangelisk-luthersk religion',
  },
  {
    id: 'filosofia',
    'fi-FI': 'Filosofia',
    'sv-FI': 'Filosofi',
  },
  {
    id: 'fysiikka',
    'fi-FI': 'Fysiikka',
    'sv-FI': 'Fysik',
  },
  {
    id: 'historia',
    'fi-FI': 'Historia',
    'sv-FI': 'Historia',
  },
  {
    id: 'saame-inari',
    'fi-FI': 'Inarinsaame',
    'sv-FI': 'Enaresamiska',
  },
  {
    id: 'saame-inari-aidinkieli-ja-kirjallisuus',
    'fi-FI': 'Inarinsaame, äidinkieli ja kirjallisuus',
    'sv-FI': 'Enaresamiska, modersmål och skönlitteratur',
  },
  {
    id: 'saame-inari-lyhyt',
    'fi-FI': 'Inarinsaame, lyhyt oppimäärä',
    'sv-FI': 'Enaresamiska, kort lärokurs',
  },
  {
    id: 'italia',
    'fi-FI': 'Italia',
    'sv-FI': 'Italienska',
  },
  {
    id: 'italia-lyhyt',
    'fi-FI': 'Italia, lyhyt oppimäärä',
    'sv-FI': 'Italienska, kort lärokurs',
  },
  {
    id: 'käsityö',
    'fi-FI': 'Käsityö',
    'sv-FI': 'Handarbete',
  },
  {
    id: 'kemia',
    'fi-FI': 'Kemia',
    'sv-FI': 'Kemi',
  },
  {
    id: 'saame-kolta',
    'fi-FI': 'Koltansaame',
    'sv-FI': 'Skoltsamiska',
  },
  {
    id: 'saame-kolta-lyhyt',
    'fi-FI': 'Koltansaame, lyhyt oppimäärä',
    'sv-FI': 'Skoltsamiska, kort lärokurs',
  },
  {
    id: 'kotitalous',
    'fi-FI': 'Kotitalous',
    'sv-FI': 'Hushållslära',
  },
  {
    id: 'kuvatiede',
    'fi-FI': 'Kuvatiede',
    'sv-FI': 'Bildkonst',
  },
  {
    id: 'latina',
    'fi-FI': 'Latina',
    'sv-FI': 'Latin',
  },
  {
    id: 'latina-lyhyt',
    'fi-FI': 'Latina, lyhyt oppimäärä',
    'sv-FI': 'Latin, kort lärokurs',
  },
  {
    id: 'latina-pidempi',
    'fi-FI': 'Latina, pidempi oppimäärä',
    'sv-FI': 'Latin, längre lärokurs',
  },
  {
    id: 'maantiede',
    'fi-FI': 'Maantiede',
    'sv-FI': 'Geografi',
  },
  {
    id: 'matematiikka',
    'fi-FI': 'Matematiikka',
    'sv-FI': 'Matematik',
  },
  {
    id: 'matematiikka-lyhyt',
    'fi-FI': 'Matematiikka, lyhyt oppimäärä',
    'sv-FI': 'Matematik, kort lärokurs',
  },
  {
    id: 'matematiikka-pitkä',
    'fi-FI': 'Matematiikka, pitkä oppimäärä',
    'sv-FI': 'Matematik, lång lärokurs',
  },
  {
    id: 'musiikki',
    'fi-FI': 'Musiikki',
    'sv-FI': 'Musik',
  },
  {
    id: 'muu',
    'fi-FI': 'Muu',
    'sv-FI': 'Övrigt',
  },
  {
    id: 'uskonto-ortodoksinen',
    'fi-FI': 'Ortodoksinen uskonto',
    'sv-FI': 'Ortodox religion',
  },
  {
    id: 'saame-pohjois',
    'fi-FI': 'Pohjoissaame',
    'sv-FI': 'Nordsamiska',
  },
  {
    id: 'saame-pohjois-aidinkieli-ja-kirjallisuus',
    'fi-FI': 'Pohjoissaame, äidinkieli ja kirjallisuus',
    'sv-FI': 'Nordsamiska, modersmål och skönlitteratur',
  },
  {
    id: 'saame-pohjois-lyhyt',
    'fi-FI': 'Pohjoissaame, lyhyt oppimäärä',
    'sv-FI': 'Nordsamiska, kort lärokurs',
  },
  {
    id: 'portugali',
    'fi-FI': 'Portugali',
    'sv-FI': 'Portugisiska',
  },
  {
    id: 'portugali-lyhyt',
    'fi-FI': 'Portugali, lyhyt oppimäärä',
    'sv-FI': 'Portugisiska, kort lärokurs',
  },
  {
    id: 'psykologia',
    'fi-FI': 'Psykologia',
    'sv-FI': 'Psykologi',
  },
  {
    id: 'ranska',
    'fi-FI': 'Ranska',
    'sv-FI': 'Franska',
  },
  {
    id: 'ranska-lyhyt',
    'fi-FI': 'Ranska, lyhyt oppimäärä',
    'sv-FI': 'Franska, kort lärokurs',
  },
  {
    id: 'ranska-pitkä',
    'fi-FI': 'Ranska, pitkä oppimäärä',
    'sv-FI': 'Franska, lång lärokurs',
  },
  {
    id: 'ruotsi',
    'fi-FI': 'Ruotsi',
    'sv-FI': 'Svenska',
  },
  {
    id: 'ruotsi-toisena',
    'fi-FI': 'Ruotsi toisena kielenä',
    'sv-FI': 'Svenska som andra språk',
  },
  {
    id: 'ruotsi-keskipitkä',
    'fi-FI': 'Ruotsi, keskipitkä oppimäärä',
    'sv-FI': 'Svenska, medellång lärokurs',
  },
  {
    id: 'ruotsi-pitkä',
    'fi-FI': 'Ruotsi, pitkä oppimäärä',
    'sv-FI': 'Svenska, lång lärokurs',
  },
  {
    id: 'saksa',
    'fi-FI': 'Saksa',
    'sv-FI': 'Tyska',
  },
  {
    id: 'saksa-lyhyt',
    'fi-FI': 'Saksa, lyhyt oppimäärä',
    'sv-FI': 'Tyska, kort lärokurs',
  },
  {
    id: 'saksa-pitkä',
    'fi-FI': 'Saksa, pitkä oppimäärä',
    'sv-FI': 'Tyska, lång lärokurs',
  },
  {
    id: 'suomi-toisena',
    'fi-FI': 'Suomi toisena kielenä',
    'sv-FI': 'Finska som andra språk',
  },
  {
    id: 'suomi-keskipitkä',
    'fi-FI': 'Suomi, keskipitkä oppimäärä',
    'sv-FI': 'Finska, medellång lärokurs',
  },
  {
    id: 'suomi-pitkä',
    'fi-FI': 'Suomi, pitkä oppimäärä',
    'sv-FI': 'Finska, lång lärokurs',
  },
  {
    id: 'terveystieto',
    'fi-FI': 'Terveystieto',
    'sv-FI': 'Hälsokunskap',
  },
  {
    id: 'venäjä',
    'fi-FI': 'Venäjä',
    'sv-FI': 'Ryska',
  },
  {
    id: 'venäjä-lyhyt',
    'fi-FI': 'Venäjä, lyhyt oppimäärä',
    'sv-FI': 'Ryska, kort lärokurs',
  },
  {
    id: 'venäjä-pitkä',
    'fi-FI': 'Venäjä, pitkä oppimäärä',
    'sv-FI': 'Ryska, lång lärokurs',
  },
  {
    id: 'yhteiskuntaoppi',
    'fi-FI': 'Yhteiskuntaoppi',
    'sv-FI': 'Samhällslära',
  },
  {
    id: 'näkövammaiset-englanti-lyhyt',
    'fi-FI': 'Näkövammaiset, englanti, lyhyt oppimäärä',
    'sv-FI': 'Synskadade, engelska, kort lärokurs',
  },
  {
    id: 'näkövammaiset-ranska-lyhyt',
    'fi-FI': 'Näkövammaiset, ranska, lyhyt oppimäärä',
    'sv-FI': 'Synskadade, franska, kort lärokurs',
  },
  {
    id: 'näkövammaiset-matematiikka-lyhyt',
    'fi-FI': 'Näkövammaiset, matematiikka, lyhyt oppimäärä',
    'sv-FI': 'Synskadade, matematik, kort lärokurs',
  },
  {
    id: 'näkövammaiset-matematiikka-pitkä',
    'fi-FI': 'Näkövammaiset, matematiikka, pitkä oppimäärä',
    'sv-FI': 'Synskadade, matematik, lång lärokurs',
  },
  {
    id: 'näkövammaiset-biologia',
    'fi-FI': 'Näkövammaiset, biologia',
    'sv-FI': 'Synskadade, biologi',
  },
  {
    id: 'näkövammaiset-yhteiskuntaoppi',
    'fi-FI': 'Näkövammaiset, yhteiskuntaoppi',
    'sv-FI': 'Synskadade, Samhällslära',
  },
  {
    id: 'näkövammaiset-ruotsi-keskipitkä',
    'fi-FI': 'Näkövammaiset, ruotsi, keskipitkä oppimäärä',
    'sv-FI': 'Synskadade, Svenska, medellång lärokurs',
  },
  {
    id: 'näkövammaiset-terveystieto',
    'fi-FI': 'Näkövammaiset, terveystieto',
    'sv-FI': 'Synskadade, hälsokunskap',
  },
  {
    id: 'näkövammaiset-psykologia',
    'fi-FI': 'Näkövammaiset, psykologia',
    'sv-FI': 'Synskadade, psykologi',
  },
  {
    id: 'näkövammaiset-englanti-pitkä',
    'fi-FI': 'Näkövammaiset, englanti, pitkä oppimäärä',
    'sv-FI': 'Synskadade, psykologi',
  },
  {
    id: 'näkövammaiset-äidinkieli-kirjoitustaidon-koe',
    'fi-FI': 'Näkövammaiset, äidinkieli, kirjoitustaidon koe',
    'sv-FI': 'Synskadade, modersmål, essäprov',
  },
  {
    id: 'näkövammaiset-äidinkieli-lukutaidon-koe',
    'fi-FI': 'Näkövammaiset, äidinkieli, lukutaidon koe',
    'sv-FI': 'Synskadade, modersmål, läskompetensprovet',
  },
  {
    id: 'näkövammaiset-maantiede',
    'fi-FI': 'Näkövammaiset, maantiede',
    'sv-FI': 'Synskadade, geografi',
  },
  {
    id: 'näkövammaiset-historia',
    'fi-FI': 'Näkövammaiset, historia',
    'sv-FI': 'Synskadade, historia',
  },
  {
    id: 'näkövammaiset-suomi-toisena-kielenä',
    'fi-FI': 'Näkövammaiset, suomi toisena kielenä',
    'sv-FI': 'Synskadade, finska som andra språk',
  },
  {
    id: 'näkövammaiset-evankelisluterilainen-uskonto',
    'fi-FI': 'Näkövammaiset, evankelisluterilainen uskonto',
    'sv-FI': 'Synskadade, evangelisk-luthersk religion',
  },
]

export default SUBJECTS
