import React from 'react'
import { connect } from 'react-redux'
import Button from '../../elements/Button/Button.tsx'
import './login.scss'
import { onPressLogin } from '../../../webviewApi/actions.ts'

const onTunnusSdkLogin = () => window.yleTehtavaApp.yleTunnus.login()
const onWebviewLogin = dispatch => dispatch(onPressLogin())
export const onClickLogin = dispatch => {
  if (IS_WEBVIEW_EMBED) {
    onWebviewLogin(dispatch)
  } else {
    onTunnusSdkLogin()
  }
}

export const LoginButton = ({ t, classNames = [], dispatch, ...props }) => (
  <Button
    classNames={['yo-tunnus-button', ...classNames]}
    onClick={() => onClickLogin(dispatch)}
    size="sm"
    text={t('YleTunnus:login')}
    {...props}
  />
)

export const LoginLink = connect()(({ children, dispatch, className = '', ...props }) => {
  return (
    <a
      className={['yo-tunnus-login-link', className].join(' ')}
      onClick={() => onClickLogin(dispatch)}
      onKeyDown={e => e.key === 'Enter' && onClickLogin(dispatch)}
      role="button"
      tabIndex={0}
      {...props}
    >
      {children}
    </a>
  )
})
